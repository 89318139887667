const page = {
  dongzuo: '动作',
  xiju: '喜剧',
  kehuan: '科幻',
  kongbu: '恐怖',
  zhanzheng: '战争',
  xuanyi: '悬疑',
  guzhuang: '古装',
  aiqing: '爱情',
  donghua: '动画',
  fanzui: '犯罪'
}

const setMeta = {
  movie: {
    title: '电影推荐|最新热门电影排行榜 – 迅雷影视',
    description: '迅雷影视，专注于好电影推荐，为你提供了最新热门电影排行榜和最精彩的电影评论，帮你快速找到最新热门的电影推荐资源',
    keywords: '电影推荐,电影排行榜,迅雷影视,迅雷影评'
  },
  reying: {
    title: '迅雷影院热映电影 – 迅雷影视',
    description: '提供最新上映的影院热映电影。迅雷影视爱好者的真实评分评论，推荐你看哪部影院热映电影',
    keywords: '迅雷影院,热映电影,上映电影'
  },
  remen: {
    title: '热门电影 – 迅雷影视',
    description: '提供大家最爱看的热门电影。迅雷影视爱好者的真实评分评论，帮你挑选最爱看的热门电影',
    keywords: '热门电影,经典电影,高分电影'
  }
}

export {setMeta, page}
