const env = process.env.NODE_ENV
let envObj = {}

if (env === 'production') {
  envObj = {
    redis: {
      port: 6382, // Redis port
      host: '10.33.7.102', // Redis host
      family: 4, // 4 (IPv4) or 6 (IPv6)
      password: 'xl9-redis-auth',
      db: 7
    },
    baseUrl: 'https://api-xl9-ssl.xunlei.com/tube',
    movieUrl: 'https://m.movie.xunlei.com'
  }
} else if (env === 'development') {
  envObj = {
    redis: {
      port: 6379, // Redis port
      host: '127.0.0.1', // Redis host
      family: 4, // 4 (IPv4) or 6 (IPv6)
      password: 'auth',
      db: 0
    },
    baseUrl: 'https://api-xl9-ssl.xunlei.com/tube',
    movieUrl: 'https://m.movie.xunlei.com'
  }
}
export default envObj
