<template>
  <div>
    <header class="navigator">
      <div class="sl-icon">
        <a href="http://mobile.xunlei.com">
          <img
            class="sl-icon-img" src="~/assets/movie/img/logo.png"
            alt="迅雷"
          >
        </a>
      </div>
      <div v-swiper:headSwiper="swiperOption" class="my-swiper bread-crumbs">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide bread-crumbs-item"
            v-for="(item, index) in breadCrumbs"
            :key="index"
            ref="breadCrumbs"
          >
            <nuxt-link class="item-link" :to="item.path">
              <span class="item-name">{{ item.name }}</span>
            </nuxt-link>
          </div>
        </div>
        <div class="swiper-pagination swiper-pagination-bullets" />
      </div>
      <div class="bread-crumbs-more" @click="moreOper" />
    </header>
    <section>
      <nuxt-child v-if="showPage" :banners="banners" />
    </section>
    <section class="dialog" v-if="dialogShow">
      <div class="dialog-title">
        导航
      </div>
      <ul class="dialog-operation">
        <li class="dialog-operation-item">
          <nuxt-link class="large" to="/movie/reying">
            影院热映
          </nuxt-link>
        </li>
        <li class="dialog-operation-item">
          <nuxt-link class="large" to="/movie/remen">
            热门电影
          </nuxt-link>
        </li>
        <li class="dialog-operation-item">
          <nuxt-link to="/movie/dongzuo">
            动作电影
          </nuxt-link>
        </li>
        <li class="dialog-operation-item">
          <nuxt-link to="/movie/xiju">
            喜剧电影
          </nuxt-link>
        </li>
        <li class="dialog-operation-item">
          <nuxt-link to="/movie/kehuan">
            科幻电影
          </nuxt-link>
        </li>
        <li class="dialog-operation-item">
          <nuxt-link to="/movie/kongbu">
            恐怖电影
          </nuxt-link>
        </li>
        <li class="dialog-operation-item">
          <nuxt-link to="/movie/zhanzheng">
            战争电影
          </nuxt-link>
        </li>
        <li class="dialog-operation-item">
          <nuxt-link to="/movie/xuanyi">
            悬疑电影
          </nuxt-link>
        </li>
        <li class="dialog-operation-item">
          <nuxt-link to="/movie/guzhuang">
            古装电影
          </nuxt-link>
        </li>
        <li class="dialog-operation-item">
          <nuxt-link to="/movie/aiqing">
            爱情电影
          </nuxt-link>
        </li>
        <li class="dialog-operation-item">
          <nuxt-link to="/movie/donghua">
            动画电影
          </nuxt-link>
        </li>
        <li class="dialog-operation-item">
          <nuxt-link to="/movie/fanzui">
            犯罪电影
          </nuxt-link>
        </li>
      </ul>
      <div class="dialog-close" @click="closeDialog" />
    </section>
  </div>
</template>

<script>
import Vue from 'vue'
import VueLazyLoad from 'vue-lazyload'
import envObj from '~/assets/movie/js/env'
import { setMeta, page } from '~/assets/movie/js/setMeta'
import { request } from '~/components/api/movie/axios'

const movieUrl = envObj.movieUrl

Vue.use(VueLazyLoad, {
  loading: require('@/static/movie/loading.jpg')
})

// 判断环境
if (process.browser) {
  var VueAwesomeSwiper = require('vue-awesome-swiper')
  Vue.use(VueAwesomeSwiper)
}

export default {
  data () {
    return {
      dialogShow: false,
      breadCrumbs: [
        { name: '影评首页', path: '/movie' },
        { name: '影院热映', path: '/movie/reying' },
        { name: '热门电影', path: '/movie/remen' }
      ],
      swiperOption: {
        slidesPerView: 'auto',
        centeredSlides: true
      },
      nowPage: '',
      showPage: true
    }
  },
  async asyncData ({ route, redirect }) {
    const dataObj = {
      banners: [
        { review_cover: '' },
        { review_cover: '' },
        { review_cover: '' },
        { review_cover: '' }
      ]
    }

    // 浏览器环境不发请求
    if (!process.browser && !route.fullPath.includes('error')) {
      let flag = 4
      const reg = /\[.+\]/g
      const date = new Date()
      const time = `${date.getFullYear()}-${date.getMonth() +
        1}-${date.getDate()}`

      let data = await request({
        url: 'https://static-xl9-ssl.xunlei.com/json/home_v2_review_banner.json',
        method: 'get',
        cache: true
      }).catch(e => {
        redirect(302, '/movie/error', { errorfrom: route.fullPath })
      })

      if (data) {
        data = JSON.parse(data.match(reg))
        data = data.filter(item => {
          if (flag > 0) {
            if (time >= item.online_time) {
              item.path = `${movieUrl}/reviews/${item.review_id}?entrypage=xl_mobile_brandsite_movie_homepage&entry=xl_mobile_movie_home_banner`
              item.review_cover = item.review_cover + '?w=660&h=372'
              flag -= 1
              return true
            }
          }
          return false
        })
        dataObj.banners = data
      }
    } else {
      if (window.localStorage.banners) {
        const data = window.localStorage.banners
        dataObj.banners = JSON.parse(data)
      }
    }

    return dataObj
  },
  // 设置页面head 优化seo
  head () {
    const path = this.$route.path
    const arr = path.split('/').filter(item => item)
    const params = arr.pop()
    let newMeta = {}
    this.nowPage = params
    if (params === 'movie' || params === 'reying' || params === 'remen') {
      newMeta = setMeta[params]
    } else {
      newMeta = {
        title: `${page[params]}电影 – 迅雷影视`,
        description: `喜欢${page[params]}电影的爱好者，这里提供了最全面最新热门的${page[params]}电影排行榜，基于众多迅雷影视爱好者的真实评分评论，总会有一部你喜欢的${page[params]}电影`,
        keywords: `${page[params]}电影,${page[params]}电影推荐`
      }
    }
    return {
      title: newMeta.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: newMeta.description
        },
        { hid: 'keywords', name: 'keywords', content: newMeta.keywords }
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: '/movie/favicon.ico' }]
    }
  },
  mounted () {
    this.showPage = true
    if (this.banners[0].review_cover) {
      window.localStorage.banners = JSON.stringify(this.banners)
    }
    window.onload = function () {
      document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      })
      var lastTouchEnd = 0
      document.addEventListener(
        'touchend',
        function (event) {
          var now = new Date().getTime()
          if (now - lastTouchEnd <= 300) {
            event.preventDefault()
          }
          lastTouchEnd = now
        },
        false
      )
      document.addEventListener('gesturestart', function (event) {
        event.preventDefault()
      })
    }
  },
  watch: {
    nowPage () {
      const arr = this.$refs.breadCrumbs
      const active = this.nowPage
      arr.forEach(item => {
        item.classList.remove('active-page')
      })
      if (active === 'movie') {
        arr[0].classList.add('active-page')
        this.headSwiper.slideTo(0)
      } else if (active === 'reying') {
        arr[1].classList.add('active-page')
        this.headSwiper.slideTo(1)
      } else if (active === 'remen') {
        arr[2].classList.add('active-page')
        this.headSwiper.slideTo(2)
      }
    },
    $route: {
      handler () {
        this.showPage = false
        this.closeDialog()
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        this.$nextTick(() => {
          this.showPage = true
        })
      },
      deep: true
    }
  },
  methods: {
    moreOper () {
      this.dialogShow = true
      document.documentElement.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
    },
    closeDialog () {
      this.dialogShow = false
      document.documentElement.style.overflow = 'visible'
      document.body.style.position = 'static'
    }
  }
}
</script>
<style lang="scss">
@import '~assets/movie/scss/common.scss';
.navigator {
  position: fixed;
  background-color: #fff;
  z-index: 2;
  top: 0;
  width: 1080px;
  height: 150px;
  line-height: 150px;
  font-size: 0;
  .sl-icon {
    display: inline-block;
    width: 351px;
    height: 150px;
    line-height: 150px;
    text-align: center;
    font-size: 45px;
    a {
      display: inline-block;
      line-height: 150px;
      .sl-icon-img {
        width: 244px;
        height: 90px;
        vertical-align: middle;
      }
    }
  }
  .bread-crumbs-more {
    float: right;
    width: 147px;
    height: 150px;
    background-image: url('~assets/movie/img/more.png');
    background-size: 72px 72px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .bread-crumbs {
    display: inline-block;
    vertical-align: top;
    padding: 0 30px;
    overflow: hidden;
    width: 572px;
    height: 150px;
    .bread-crumbs-item {
      width: auto;
      margin-left: 30px;
      .item-link {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
      .item-name {
        font-size: 42px;
        color: #979aa0;
      }
    }
    .active-page {
      .item-name {
        font-size: 51px;
        color: #262a2f;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 120px;
        height: 6px;
        background-color: #1aa3ff;
      }
    }
    &::before,
    &::after {
      content: '';
      z-index: 2;
      position: absolute;
      top: 0;
      width: 30px;
      height: 150px;
    }
    &::before {
      left: 0;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
    }
    &::after {
      right: 0;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
    }
  }
}
.dialog {
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.95;
  &-title {
    height: 132px;
    line-height: 132px;
    font-size: 45px;
    color: #262a2f;
    padding-left: 51px;
    &::before {
      content: '';
      display: inline-block;
      vertical-align: text-top;
      margin-right: 15px;
      width: 6px;
      height: 54px;
      border-radius: 3px;
      background-color: #1aa3ff;
    }
  }
  &-operation {
    overflow: hidden;
    &-item {
      float: left;
      width: 360px;
      height: 156px;
      line-height: 156px;
      text-align: center;
      a {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        width: 102px;
        font-size: 51px;
        color: #4677bc;
      }
      .large {
        width: 204px;
      }
    }
  }
  &-close {
    width: 90px;
    height: 90px;
    margin: 180px auto 0;
    background-image: url('~assets/movie/img/close.png');
    background-size: 100%;
    background-position: center;
  }
}
</style>
